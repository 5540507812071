/* Hide scrollbar for Chrome, Safari and Opera */
.scrollable::-webkit-scrollbar {
    overflow: scroll !important;
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .scrollable {
    overflow: scroll !important;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

.textInput{
    height: 100%;
    background-color: rgba(22,22,22,0.7);
    color:white;
    text-align: "top";
    border: none;
    border-radius: 16px;
    /* font-size: 24px; */
}

.textInput::placeholder{
    color: rgba(255,255,255,0.7);
}

.textInput:focus{
    background-color:transparent !important;
    color: white;
    border: none !important;
    outline-width: 0 !important;
    outline: none !important;
}


.buttonHidden{
    background-color: transparent;
}

.buttonHidden:focus{
    background-color: transparent;
}

.buttonSend:disabled{
    background-color: rgba(255,255,255,0.7);
    color:white;
}

.buttonSend{
    background-color: rgba(255,255,255,0.7) !important;
    color:black !important;
}

.buttonSend:hover{
    background-color: rgba(255,255,255,1) !important;
    color:black !important;
}